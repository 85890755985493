@import "themes";

.projectFilter>div{
    text-align: center;
    display: flex;
    align-items: center;
}

.tgl {
  display: none;

  + .tgl-btn {
    content: attr(data-tg-on);
    height: 2em;
    position: relative;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    backface-visibility: hidden;
    transition: all 0.2s ease;
    vertical-align: middle;

    font-size: 0.8em;

    &:after,
    &:before {
        transition: all 0.2s ease;
        width: 100%;
        position: absolute;
        line-height: 1.8em;
        font-weight: 400;
        color: $color_registration;
    }
    &:after {
        left: 100%;
        content: attr(data-tg-on);
      }
  
      &:before {
        left: 0;
        content: attr(data-tg-off);
      }
  
      &:active {
        background: #888;
        border-radius: 0.5em;
        &:before {
          left: -10%;
          border-radius: 0.5em;
        }
      }

      
  }

  &:checked + .tgl-btn {
    border-color: transparent; /* remove the border's colour */
    box-shadow: 0 0 0 2px $color_accent; /* emulate the border */
    border-radius: 1em;
    vertical-align: middle;

    &:after,
    &:before {
    }
    &:before {
        left: -100%;
        border-radius: 0;
    }

    &:after {
        left: 0;
    }

    &:active:after {
        background: #888;
        border-radius: 0.5em;
        left: 10%;
    }
  }
}
