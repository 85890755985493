@media (max-width: 600px) {
    #root, #root>* {
        display: block;
        flex-direction: column;
        overflow: hidden;
    }

    .mainContainer {
        width: 100%;
    }

    .left {
        background-image: url('../assets/profilePic01.JPG');
        background-repeat: no-repeat;
        background-size: auto 60%;
        background-position: 100% 100%;

        .logo {
            width: calc(100vw - 26px);
        }
    }   

    .right {
        left: 100%;
        visibility: hidden;
    }

    

    .projectContentContainer {
        padding: 3em 1em;
        padding-right: 3.5em;
    }

    .footer {
        width: 100vw;
    }

    .link {
        $self: &;
        &__line {
            &:after {
                animation: animation-line-looped 1.5s forwards;
                // animation-duration: 3s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
            }
        }

        &__arrow {
            span {
                &:after {
                animation: animation-arrow-looped;
                // animation-delay: 1s;
                animation-duration: 1.5s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                }
            }
        }
    
    }

    @keyframes animation-line-looped {
        0% {
          left: 140px;
        }

        70%{
            left: 0;
        }
      
        100% {
          left: -30px;
        }
      }

    @keyframes animation-arrow-looped {
        0% {
          width: 0;
        }
        
        70% {
            width: 0;
        }

        100% {
          width: 100%;
        }
      }

      .contact {
        h1 {
            
        }

        h2{

        }

        & > div:nth-child(3) {
            gap: 5vw;
        }

        & img {
            height: 10vw;
            width: auto;
        }
      }

    footer {
        width: 100%;
    }
    
}

@media (max-height: 700px) {

    .left {
        // padding: 2em 3em;
    }

    .link{
        transform: rotate(-90deg);
        &__line{
            width: 60px;
        }
    }
    .circleContainer {
        // margin-left: -10em;
    }
    // .circle {
    //     width: 10em;
    // height: 10em;
    
    // }

    .contact {
        .contactWrapper {

            & h1 {
                font-size: 18vh;
            }
            h2 {
                font-size: 2.5vh;
                margin-top: 5vh;
                margin-bottom: 2vh;
            }
            & > div:nth-child(3) {
                gap: 3vh;
            }
    
            & img {
                height: 7vh;
                width: auto;
            }
        }
    }
}

@media (max-height: 500px) {
    .link {
        flex-wrap: wrap;
        transform: rotate(-90deg);
        // background-color: red;
        width: 120px;
        padding-left: 10px;
        // width: min-content;
        &>span:last-child{
            width: 50%;
            margin-top: 1em;
            margin-left: 1em;
        }   
    }

    .projectIndex {
        height:80%;
    }
    .projectIndexSlideIn {
        top: 25%;
    }
}