@import "themes";

.projectPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
   
}

.scrollContainer {
    height: 100%;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;
}

.projectContentContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 2em 3em;
    max-width: 800px;

    img {
        max-width: 100%;
        min-width: 30%;
    }

    figure {
        margin-bottom: 0.2em;
        img{
            border-style: none;
        }
        figcaption {
            margin-left: 0em
        }
    }

    ul{
        padding-left: 2em;
    }

    h1 {
        margin-bottom: 0em;
    }
    h2 {
        margin-bottom: 1em;
        font-family: $font_default;
        font-weight: 100;
        font-size: 1em;
    }
    h3 {
        margin-top: 1em;
        margin-bottom: 0.5em;
    }
    h5{
        margin-bottom: 0.5em;
    }

    p {
        margin-bottom: 1em;
    }

    &:last-child{
        margin-bottom: 5em;
    }
    
}

.backToIndex {

    position: fixed;
    width: 100%;
    height: 2em;
    bottom: 0%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5em;
    cursor: pointer;
    
    hr {
        width: 95%;
        color: black;
        background-Color: black;
        height: 2px;
    }


}