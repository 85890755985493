@import "themes";

/*=======   INDEX TAB   ============*/
.projectIndex {
    position: fixed;
    top: 100%;
    left: 0%;
    width: 50%;
    height:60%;
    background-color:$color_paper;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 4;
}

.projectFilter {
    padding: 1em 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 0.75em;
    font-size: 0.8em;
}

.indexTableContainer {
    overflow: auto;
    width: 100%;
}

.projectIndexTable {
    
    width: 95%;
    padding: 0em 1em;
    border-collapse: collapse;
    font-size: 0.7em;
}

.projectIndexTable th {
    font-size: 0.7em;
    font-weight: 200;
    text-align: left;
    padding: 0.2em 0.5em;
}

.projectIndexTable td {
    padding: 0.5em 0.5em;
    overflow-wrap: break-word;
}

.projectIndexTable tr:hover {
    cursor: pointer;
    border-color: transparent; /* remove the border's colour */
    box-shadow: 0 0 0 0.5px rgb(211, 211, 211); /* emulate the border */
}
.projectIndexTable tr:active {
    background-color: $color_accent;
}

 .selected {
    background-color: $color_active;
}


@media (max-width: 600px) {

    .projectIndex {
        width: calc(100% - 25px);
        height:65%;
    }
}