@import 'themes';

.contact {
    position: fixed;
    background-color: $color_accent;
    width: 100%;
    height: 50%;
    bottom: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 10;

    .contactWrapper {
        width: 100%;
        padding: 0;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 5vh;
        & > div:nth-child(3) {
            display: flex;
            gap: 4em;
        }

        & h1 {
            margin-top: 0;
            margin-bottom: -0.3em;
            padding: 0;
            font-family: $font_special;
            text-transform: uppercase;
            font-size: 13em;
            // border-bottom: 1px solid black;
        }
    
        & h2 {
            margin-top: 5%;
            margin-bottom: 5%;
            font-size: 2em;
    
        }
    
        img {
            width: 2em;
            height: 2em;
            // background-color: black;
        }
    }
}

.bottomEdge {
    width: 100%;
    height: 25px;
    border-top: 0.5px dotted rgb(148, 148, 148);
    border-bottom: 1px dotted black;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.bottomEdge:hover {
    background-color: $color_paper;
}

.bottomEdge:active {
    background-color: $color_active;
}


@media (min-width: 1300px) {
    .contact {

        .contactWrapper {
            width: 100%;
            padding: 0;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 3em;
            & > div:nth-child(3) {
                display: flex;
                gap: 4em;
            }
    
            & h1 {
                margin-top: 0;
                margin-bottom: -0.3em;
                padding: 0;
                font-family: $font_special;
                text-transform: uppercase;
                font-size: 15em;
            }
        
            & h2 {
                margin-top: 1em;
                margin-bottom: 1em;
                font-size: 2em;
        
            }
        
            img {
                width: 3em;
                height: 3em;
            }
        }
    }
}

@media (max-width: 600px) {
    .contact {
        .contactWrapper {

            justify-content: space-around;
            & h1 {
                font-size: 30vw;
            }
            & h2 {
                font-size: 5vw;
            }
            img {
                width: 8vw;
                height: auto;
            }
        }

    }
}

