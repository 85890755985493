@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Montserrat:ital,wght@0,100;0,200;0,400;0,600;0,800;1,100;1,200;1,400;1,600;1,800&family=Source+Serif+Pro:ital,wght@0,200;0,400;0,700;1,200;1,400;1,700&display=swap" rel="stylesheet');

$color_paper: white;
$color_registration: #0f0f0f;
$color_accent: rgb(255, 221, 0);
$color_active: rgb(229, 229, 229);


@font-face {
    font-family: "Monolisk Black";
    src: url('../assets/monolisk_black.woff') format('woff');
}

$font_default: 'Montserrat', sans-serif;
$font_special: 'Monolisk Black', sans-serif;