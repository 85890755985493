@import "themes";

*{
    box-sizing: border-box;
    margin:0;
    padding: 0;
}

body {
    
    margin: 0;
    font-family: $font_default;
    font-size: 0.9em;
    height:100%;
    width:100%;
    
}

#root, #root>* {
    position: fixed;
    display: flex;
    justify-content: center;
    height: 100%;
    width:100%;
    align-items: stretch;
}

a {
    color: black;
    text-decoration: underline;
    text-decoration-color: $color_accent;
    text-decoration-thickness: 0.2em;
}
a:hover {
    background-color: $color_accent;
}

p{
    line-height: 1.5em;
}

.mainContainer {
    height: 100%;
    width: 50%;
    background-color: $color_paper;
    margin: 0;
    position: fixed;
}

/* LEFT MAIN */
.left {
    top:0;
    left:0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    touch-action: none;

    &>*  {
        margin-left: 4em;
        margin-right: 4em;
    }

    //BANNER --- contains logo and tagline
    .logo {
        // position: fixed;
        // top: -4px;
        // left:0;
        width: 100%;
        margin: 0;
        margin-top: -4px;
    
        svg{
            fill: black;
            width: 100%;

            &:hover {
                fill: $color_paper;
                background-color:  $color_registration
            }

            &:active {
                fill: $color_accent;
            }
        }
    
    
        h2 {
            margin-left:2.5em;
            margin-top: 0em;
            margin-bottom: 1em;
            font-size: 1.5em;
            font-weight: 200;
        }


    }


    //  HEADER --- intro, profile link and index link
    & header {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        // background-color: blue;
        height: 100%;
        
        p {
            margin-bottom: 1em;
        }
    }

    //  FULL PROFILE BUTTON
    #btn_profile {
        display: flex;
        height:2em;
        width: 8em;
        align-items: center;
        font-size: 0.7em;
        justify-content: start;
        border-radius: 1em;


        :first-child {
            padding: 0;
            margin-left: 0;
            text-align: center;
            vertical-align: center;
            margin-left: auto;
        }
        svg {
            flex-shrink: 0;
            margin:0;
            margin-left: -1.2em;
            margin-right: -1.2em;
            padding: 0;
            scale: 0.25;
            width: 40px;
            height:40px;
        }
        &:hover {
            // border-bottom: 0.3em solid $color_accent;
            box-shadow: 0 0 0 1px black;
            cursor: pointer;
            // font-weight: 600;
            stroke-width: 5;
        }
        
        &:active {
            background-color: $color_accent;
        }
    }
}


/* =======  RIGHT MAIN  ==========*/
.right {
    // top:0;
    right:0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;

    .background {
        position: absolute;
        background-image: url('../assets/profilePic00.JPG');
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: 0.2 * 100vh center;
        height: 110vh;
        width: 0.7*100vh;

    }
}

.circleContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    gap: 2em;
    background-color: black;
    height: 100%;
    width: 100%;
    mix-blend-mode: hard-light;
    z-index: 2;
}

.circle {
    background-color: gray;
    width: 18em;
    height: 18em;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
}

/* ======   CIRCLE PREVIEWS     =======*/

.preview {
    position: fixed;
    z-index:2;
    width: 18em;
    height: 18em;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
}
.banner {
    position:fixed;
    color: $color_accent;
    text-align: center;
    font-family: "Monolisk Black", sans-serif;
    font-size: 10em;
    z-index: 4;
}

.leftEdge {
    /* height: 100%; */
    width: 25px;
    /* flex-grow: 1; */
    flex-shrink: 0;
    border-right: 0.5px dotted rgb(148, 148, 148);
    border-left: 1px dotted black;
    writing-mode:vertical-lr;
    text-align: center;
    font-weight: lighter;
    vertical-align: middle;
}

.leftEdge:hover {
    background-color: $color_accent;
    font-weight: 400;
    cursor: pointer;
}

.leftEdge:active {
    background-color: $color_active;
}

.about .leftEdge {
    background-color: $color_registration;
    color: $color_paper;
    border-color: $color_paper;

}

.topEdge {
    height: 2px;
    width: 100%;
    flex-shrink: 0;
    background-color: $color_registration;
    text-align: center;
    font-weight: lighter;
    vertical-align: middle;
}


//======    Media/Graphics  ==============
figure {
    margin-right: 5em;
    width: 100%;
    margin: 0;
}

figure img {
    border-style: solid;
    border-color: black;
    border-width: 1px;
    width:100%;
    height:auto;
}

figcaption {
    font-family: 'Source Serif Pro', serif;
    font-style: italic;
    font-weight: 400;
    font-size: 0.9em;
    margin-top: 0.1em;
    margin-bottom: 0.3em;
    margin-left: 2em;
}


.compositeFigure{
    width: 100%;
    display: flex;
    flex-direction: column;
    
    & >div {
        display: flex;
        justify-content: flex-start;
        // align-items: flex-start;
        gap: 0.2em;
        width: 100%;
        // max-height: 30px;
        background-color: red;
        overflow: auto;
        margin: 0;
        padding: 0;

        .imageWrapper {
            // width: auto;
            // align-self: center;
            max-height: 20vh;
            margin: 0;
            
            img {
                height: 100%;
                width: auto;
            }
        }
    }

}

.projectVideoContainer {
    margin: 0.5em 0em;
    
}


/*========= FOOTER  ====================*/
footer {
    font-size: 0.8em;
    font-weight: 100;
    background-color: $color_paper;
    color: $color_registration;
    position: fixed;
    bottom: 0px;
    left: 0;
    z-index: 0;
    width: 50%;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px dotted $color_registration;
}


