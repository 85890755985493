@import 'themes';

#projectArrow {
//   display: inline-block;

}

#projectArrow:active {

    &.link{

        color: $color_accent;
        border-color: transparent;
        background-color: $color_active;
    }
    
    border-radius: 1em;
}

.link {
    // background-color: red;
    $self: &;
    display: flex;
    align-items: center;
    padding: 5px;
    text-decoration: none;
    transform: rotate(-90deg) translate3d(0px, 0px, 0);
    transform-origin: left top;
    cursor: pointer;

    &__arrow {
    display: inline-flex;

        span {
            position: relative;
            width: 14px;
            height: 4px;
            border-radius: 2px;
            overflow: hidden;
            background: #BEBDBE;
            z-index: 2;

            &:nth-child(1) {
            transform-origin: left bottom;
            transform: rotate(45deg) translate3d(7px, -9px, 0);
            }

            &:nth-child(2) {
            transform-origin: left bottom;
            transform: rotate(-45deg);
            }

            &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            width: 0;
            height: 6px;
            background: $color_accent;
            }
        }
    }

    &__line {
    position: relative;
    margin-left: -14px;
    margin-right: 30px;
    width: 140px;
    height: 4px;
    background: #BEBDBE;
    overflow: hidden;
    z-index: 1;

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 140px;
        width: 40px;
        height: 4px;
        background: $color_accent;
    }
    }

    &__text {
    color: #4A4A4A;
    font-size: 1em;
    }

    &:hover {
        font-weight: 400;
        background-color: transparent;
        #{$self} {
            &__line {
            &:after {
                animation: animation-line 1.2s forwards;
            }
            }

            &__arrow {
            span {
                &:after {
                animation: animation-arrow 1.2s forwards;
                animation-delay: 1s;
                }
            }
            }
        }
    }
}


@keyframes animation-line {
  0% {
    left: 140px;
  }

  100% {
    left: 0;
  }
}

@keyframes animation-arrow {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}